

.Chat-module {
  background: black;
  width: 700px;
  height: 500px;
  padding: 20px;
  margin: auto;
  color: rgba(255, 255, 255, 0.7);
  font-size: 17px;
  text-align: left;
}

.Input {
  /* caret-color: transparent; */
  border: none;
  outline: none;
  width: 100%;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

/* .Input::after {
  animation: cursor-blink 1.5s steps(2) infinite;
  content: "";
  width: 7px;
  height: 19px;
  background: white;
  display: inline-block;
} */
